import React from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFound from '../components/1_atoms/Images/NotFound';
import GenericLoader from '../components/2_molecules/Loaders/GenericLoader';
import Login from '../components/5_pages/Account/Login';
import RootIndex from '../components/5_pages/Root/RootIndex';
import RequireAuth from './RequireAuth';

const LazyAdminRoot = React.lazy(() => import('./AdminRoutes'));
const LazySignatureRoot = React.lazy(() => import('./SignatureRoutes'));
const LazyConsentementRoot = React.lazy(() => import('./ConsentementRoutes'));
const LazyCarteMembreRoot = React.lazy(() => import('./CarteMembreRoutes'));
const LazySysAdminRoot = React.lazy(() => import('./SystemAdminRoutes'));

function AdminRoutes() {
    return (
        <React.Suspense fallback={<GenericLoader/>}>
            <RequireAuth>
                <LazyAdminRoot/>
            </RequireAuth>
        </React.Suspense>
    );
}

function SystemAdminRoutes() {
    return (
        <React.Suspense fallback={<GenericLoader/>}>
            <RequireAuth>
                <LazySysAdminRoot/>
            </RequireAuth>
        </React.Suspense>
    );
}

function SignatureRoutes() {
    return (
        <React.Suspense fallback={<GenericLoader/>}>
            <LazySignatureRoot/>
        </React.Suspense>
    );
}

function ConsentenmentRoutes() {
    return (
        <React.Suspense fallback={<GenericLoader/>}>
            <LazyConsentementRoot/>
        </React.Suspense>
    );
}

function CarteMembreRoutes() {
    return (
        <React.Suspense fallback={<GenericLoader/>}>
            <LazyCarteMembreRoot/>
        </React.Suspense>
    );
}

function RootRoutes() {
    return (
        <Routes>
            <Route
                index
                element={<RootIndex/>}
            />
            <Route
                path='admin/*'
                element={<AdminRoutes/>}
            />
            <Route
                path='sysadmin/*'
                element={<SystemAdminRoutes/>}
            />
            <Route
                path='signature/*'
                element={<SignatureRoutes/>}
            />
            <Route
                path='cartemembre/*'
                element={<CarteMembreRoutes/>}
            />
            <Route
                path='consentement/*'
                element={<ConsentenmentRoutes/>}
            />
            <Route
                path='login'
                element={<Login/>}
            />
            <Route
                path='*'
                element={<NotFound/>}
            />
        </Routes>
    );
}

export default RootRoutes;
