import { LinearProgress, Skeleton, Stack } from '@mui/material';

function GenericLoader() {
  return (
    <Stack direction='row' spacing={2} p={2}>
      <Stack sx={{ flexGrow: 1 }}>
        <LinearProgress />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Stack>
      <Stack sx={{ flexGrow: 0.5 }}>
        <LinearProgress />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Stack>
    </Stack>
  );
}

export default GenericLoader;
