import { Alert, AlertColor, AlertTitle, Snackbar } from '@mui/material';
import React, { useContext } from 'react';

type ContextType = {
  show: (message: string, severity: AlertColor) => void;
};

const Context = React.createContext<ContextType | null>(null);

function ToastsProvider({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [severity, setSeverity] = React.useState<AlertColor>('info');

  const show = React.useCallback(
    (messageToDisplay: string, messageSeverity: AlertColor) => {
      setMessage(messageToDisplay);
      setSeverity(messageSeverity);
      setOpen(true);
    },
    [setMessage, setSeverity, setOpen],
  );

  const contextValue: ContextType = React.useMemo(() => ({ show }), [show]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;

    setOpen(false);
  };

  return (
    <Context.Provider value={contextValue}>
      {children}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}>
        <Alert
          sx={{ minWidth: '600px' }}
          severity={severity}
          onClose={handleClose}>
          <AlertTitle>
            {severity === 'error' && 'Erreur'}
            {severity === 'info' && 'Information'}
            {severity === 'success' && 'Succès'}
            {severity === 'warning' && 'Avertissement'}
          </AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </Context.Provider>
  );
}

export const useToastsContext = () => {
  const context = useContext(Context);

  if (context === null) {
    throw new Error('useToastsContext doit être appelé par un enfant de ToastsProvider');
  }

  return context;
};

export default ToastsProvider;
