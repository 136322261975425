import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InferType, object, string } from 'yup';

const loginSchema = object({
  courriel: string()
    .email('Doit être une adresse courriel valide')
    .required('Entrez votre courriel'),
  password: string().required('Entrez votre mot de passe'),
});

export type LoginRequest = InferType<typeof loginSchema>;
export type LoginResponse = {
  prenom: string;
  nom: string;
  token: string;
  idClient: number;
  id: number;
};

export const useLoginForm = () => useForm<LoginRequest>({ resolver: yupResolver(loginSchema) });
