import { Dialog } from '@mui/material';
import React from 'react';
// eslint-disable-next-line import/no-cycle
import { ModalContext } from './ModalContext';

const Modal = React.forwardRef<unknown>(() => {
  const { open, content, fullscreen } = React.useContext(ModalContext);
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='md'
      scroll='paper'
      fullScreen={fullscreen}
      sx={{ overflowX: 'hidden' }}>
      {content}
    </Dialog>
  );
});

export default Modal;
