import React, {useMemo} from "react";
import {createTheme, ThemeProvider} from "@mui/material";
import {useSelector} from "react-redux";
import {selectTheme} from "../../store/ThemeSlice";

function ThemeWrapper({children}: { children: React.ReactNode }) {

    // SPGQ
    // const primary = '#E50040';
    // const secondary = '#3681c3';
    
    const themeConfig = useSelector(selectTheme);

    const theme = useMemo(() => createTheme({
                palette: {primary: {main: themeConfig.primaryColor}, secondary: {main: themeConfig.secondaryColor}},
            },
        ), [themeConfig],
    );


    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}

export default ThemeWrapper;