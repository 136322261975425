/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import accountReducer, { tryRecoverAccount } from './accountSlice';
import adminReducer from './adminSlice';
import signatureReducer from './signatureSlice';
import themeReducer from './ThemeSlice';
import api from '../services/baseApi';

const existingAccount = tryRecoverAccount();

export const reducer = {
  account: accountReducer,
  admin: adminReducer,
  signature: signatureReducer,
  theme:themeReducer,
  [api.reducerPath]: api.reducer,
};

export const store = configureStore({
  reducer,
  preloadedState: {
    account: existingAccount,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
