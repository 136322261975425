// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
// eslint-disable-next-line import/no-cycle
import securityApi from '../services/securityApi';
import { TokenContent } from '../types/TokenContent';
// eslint-disable-next-line import/no-cycle
import { RootState } from './store';

const sliceName = 'account';
export type User = {
  id: number;
  nom: string;
  prenom: string;
  token: string;
  roles: string[];
  idClient: number;
};

export interface AccountSlice {
  user?: User;
}

const initialState: AccountSlice = {};

export const accountSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    logoff: (state) => {
      state.user = undefined;
      sessionStorage.clear();
    },
    // loginWithToken: (state, action: PayloadAction<string>) => {
    //   const content = jwtDecode(action.payload) as TokenContent;
    //   const roles = (content.role || '').split(',');
    //   state.user = {
    //     Courriel: '',
    //     Nom: content.name || '',
    //     Prenom: '',
    //     Token: action.payload,
    //     Roles: roles,
    //   };
    //   state.roles = roles;
    //   sessionStorage.setItem('account', JSON.stringify(state));
    // },
  },

  extraReducers: (builder) => {
    builder.addMatcher(securityApi.endpoints.login.matchFulfilled, (state, action) => {
      const tokenContent = jwtDecode(action.payload.token) as TokenContent;
      const roles = tokenContent.role?.split(',') || [];
      state.user = { ...action.payload, roles };
      sessionStorage.setItem('account', JSON.stringify(state));
    });
  },
});

export const tryRecoverAccount = () => {
  const accountDansSession = sessionStorage.getItem('account');
  if (!accountDansSession) return undefined;
  return JSON.parse(accountDansSession) as AccountSlice;
};

export const { logoff } = accountSlice.actions;
export const accountSliceName = accountSlice.name;

export const selectUser = (state: RootState) => state.account.user;

export default accountSlice.reducer;

export {};
