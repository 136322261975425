/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from '@mui/material';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginRequest, useLoginForm } from '../../../../services/Schemas/LoginSchema';
import { useLoginMutation } from '../../../../services/securityApi';
import SubmitButton from '../../../1_atoms/Buttons/SubmitButton';
import ApiError from '../../../2_molecules/Errors/ApiError';
import FormTextInput from '../../../2_molecules/Inputs/FormTextInput';
import Form from '../Form';

function LoginForm() {
  const [triggerLogin, { error }] = useLoginMutation();
  const navigate = useNavigate();
  const location = useLocation();

  const from = (location.state as any)?.from?.pathname || '/';
  const loginSubmit: SubmitHandler<LoginRequest> = async (data) => {
    await triggerLogin(data)
      .unwrap()
      .then(() => navigate(from, { replace: true }));
  };

  const methods = useLoginForm();

  return (
    <FormProvider {...methods}>
      <Form onSubmit={loginSubmit}>
        <Stack spacing={2}>
          <ApiError error={error} />
          <FormTextInput
            name='courriel'
            label='Courriel'
          />
          <FormTextInput
            name='password'
            label='Mot de passe'
            type='password'
          />
          <SubmitButton>Continuer</SubmitButton>
        </Stack>
      </Form>
    </FormProvider>
  );
}

export default LoginForm;
