// On réassigne state mais c'est prévu par redux-toolkit
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from './store';

const sliceName = 'admin';
export interface AdminSlice {
  sideNavigationOpened: boolean;
}

const initialState: AdminSlice = { sideNavigationOpened: true };

export const adminSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    toggleNav: (state) => {
      state.sideNavigationOpened = !state.sideNavigationOpened;
    },
  },
  // extraReducers: (builder) => {},
});

export const { toggleNav } = adminSlice.actions;
export const accountSliceName = adminSlice.name;

export const selectIsSideNavOpened = (state: RootState) => state.admin.sideNavigationOpened;

export default adminSlice.reducer;

export {};
