import ImagePageNotFound from '../../../assets/images/undraw_page_not_found.svg';
import PageContent from "../../4_templates/PageContent";

function NotFound() {
    return (
        <PageContent header='Page non trouvée'>
            <div style={{display: 'flex'}}>
                <img
                    src={ImagePageNotFound}
                    style={{
                        padding: '4rem',
                        width: '100%',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                    }}
                    alt='404 Not found'
                />
            </div>
        </PageContent>
    );
}

export default NotFound;
