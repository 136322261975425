// eslint-disable-next-line import/no-cycle
import baseApi from './baseApi';
import type {
  EmployeSignatureGetQuery,
  EmployeSignatureGetData,
} from './Schemas/EmployeSignatureGet';
import type { SignerCarteCommand, SignerCarteResponse } from './Schemas/SignerCarte';
import type { ConfigNouveauMembreGetData } from './Schemas/SignatureNouveauMembre';

const signatureApi = baseApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    /// NOTE: Voir SignatureSlice pour le matcher lorsque cette requete est reussie
    getEmployeSignature: builder.query<EmployeSignatureGetData, EmployeSignatureGetQuery>({
      query: ({ idUnique }) => `signature/${idUnique}`,
      providesTags: (_result, _error, args) => [{ type: 'EmployesSignature', id: args.idUnique }],
    }),
    signerCarte: builder.mutation<SignerCarteResponse, SignerCarteCommand>({
      query: ({ ...body }) => ({
        url: `signature/${body.idUnique}`,
        method: 'POST',
        body,
      }),
    }),
    getConfigNouveauMembre: builder.query<ConfigNouveauMembreGetData, string>({
      query: (args) => `signature/ConfigNouveauMembre/${args}`,
      providesTags: (_result, _error, args) => [{ type: 'ConfigNouveauMembre', id: args }],
    }),
  }),
});

export const {
  useGetEmployeSignatureQuery,
  useSignerCarteMutation,
  useGetConfigNouveauMembreQuery,
} = signatureApi;

export default signatureApi;
