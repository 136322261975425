import { CssBaseline} from '@mui/material';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ModalProvider} from './components/3_organisms/Modals/ModalContext';
import ToastsProvider from './components/3_organisms/Toasts/ToastsProvider';
import RootRoutes from './routing/RootRoutes';
import {store} from './store/store';
import ThemeWrapper from "./components/4_templates/ThemeWrapper";

function App() {

    return (
        <Provider store={store}>
            <ThemeWrapper>
                <ToastsProvider>
                    <CssBaseline/>
                    <BrowserRouter>
                        <ModalProvider>
                            <RootRoutes/>
                        </ModalProvider>
                    </BrowserRouter>
                </ToastsProvider>
            </ThemeWrapper>
        </Provider>
    );
}

export default App;
