import { useSelector } from 'react-redux';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { selectUser } from '../store/accountSlice';

function RequireAuth({ children }: { children: JSX.Element }) {
  const user = useSelector(selectUser);
  const location = useLocation();
  const [queryParameters] = useSearchParams();
  // const dispatch = useAppDispatch();

  // On est loggé
  if (user) return children;

  // Check si on peut logger avec un token dans url
  const token = queryParameters.get('token');

  // Pas de token dans l'url, on va au login
  if (!token)
    return (
      <Navigate
        to='/login'
        state={{ from: location }}
        replace
      />
    );

  // On log avec le token
  // dispatch(loginWithToken(token));

  return (
    <Navigate
      to={location}
      replace
    />
  );
}

export default RequireAuth;
