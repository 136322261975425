import { Button, ButtonProps, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useFormState } from 'react-hook-form';

export type SubmitButtonProps = ButtonProps & {
  loading?: boolean;
  children?: React.ReactNode;
};

function SubmitButton({
  loading,
  type = 'submit',
  children = 'Soumettre',
  disabled,
  ...rest
}: SubmitButtonProps) {
  const { isSubmitting } = useFormState();
  return (
    <Button
      variant='contained'
      color='primary'
      {...rest}
      type={type}
      disabled={loading || isSubmitting || disabled}>
      {(loading || isSubmitting) && <CircularProgress size='1em' />}
      <Typography
        variant='body2'
        sx={{ ml: 1 }}>
        {children}
      </Typography>
    </Button>
  );
}

export default SubmitButton;
