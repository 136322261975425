import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function RootIndex() {
  return (
    <Box sx={{ backgroundColor: 'primary.light', minHeight: '100vh' }}>
      <Container maxWidth='md'>
        <Stack
          component={Paper}
          spacing={2}
          justifyContent='center'
          alignItems='center'
          p={8}>
          <Typography variant='h3'>Ma carte de membre</Typography>
          <Typography variant='body1'>
            Vous pouvez accéder à{' '}
            <Button
              variant='text'
              component={Link}
              to='/admin'>
              Administration
            </Button>{' '}
            ou signer votre carte de membre via un courriel que vous avez recu.
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}

export default RootIndex;
