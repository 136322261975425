// eslint-disable-next-line import/no-cycle
import baseApi from './baseApi';
import { LoginRequest, LoginResponse } from './Schemas/LoginSchema';
import {
  UtilisateurChangerMotDePasseCommand,
  UtilisateurChangerMotDePasseResponse,
} from './Schemas/UtilisateurChangerMotDePasse';

const securityApi = baseApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    // Login a user with courriel and password
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: ({ ...body }) => ({
        url: 'security/login',
        method: 'POST',
        body,
      }),
    }),
    changerMotDePasse: builder.mutation<
      UtilisateurChangerMotDePasseResponse,
      UtilisateurChangerMotDePasseCommand
    >({
      query: ({ ...body }) => ({
        url: 'security/changerMotDePasse',
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { useLoginMutation, useChangerMotDePasseMutation } = securityApi;

export default securityApi;
