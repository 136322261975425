import React from 'react';

interface StateType {
  open: boolean;
  fullscreen: boolean;
  content: JSX.Element;
  shouldClose: boolean;
}

const emptyState = { open: false, shouldClose: false, fullscreen: false, content: <div /> };

function useModal() {
  const [modalState, setModalState] = React.useState<StateType>(emptyState);

  React.useEffect(() => {
    if (modalState.shouldClose) {
      setModalState(emptyState);
    }
  }, [modalState]);

  const openModal = (content: JSX.Element, fullscreen = false) => {
    setModalState({ open: true, shouldClose: false, content, fullscreen });
  };

  const closeModal = () => {
    setModalState((state) => ({ ...state, shouldClose: true }));
  };

  const { open, content, fullscreen } = modalState;

  return { openModal, closeModal, open, content, fullscreen };
}

export default useModal;
