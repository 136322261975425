import { omitBy } from 'lodash';

// Base RT Query service to query Votez backend
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../store/store';
// eslint-disable-next-line import/no-cycle
import { logoff } from '../store/accountSlice';

const baseUrl = '/api';

// Strip out all values with empty strings
function isEmptyValues(value?: any) {
  return (
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

// type ApiError = {
//     title: string;
//     status: number;
//     traceId: string;
//     errors: string[];
// }

export const whitespacesAsNull = <T>(values: T) => omitBy(values, isEmptyValues) as T;

// Prepare the base query and add security token if available
const baseQuery = fetchBaseQuery({
  baseUrl,

  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).account.user?.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(logoff());
  }
  return result;
};

const adminApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Adhesions',
    'Clients',
    'ConfigClient',
    'Demissions',
    'Employes',
    'EmployesSignature',
    'Employeurs',
    'Envois',
    'ObjetConsentement',
    'SyndicatRivals',
    'MaraudagesParEmployeur',
    'ProprieteMembrePerClient',
    'Utilisateurs',
    'WalletTemplate',
    'ProprieteDynamique',
    'ConfigNouveauMembre',
  ],
  endpoints: () => ({}),
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
});

export default adminApi;
