import { Box, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';

function FormHeader({ sx, children }: { sx?: SxProps<Theme>; children: React.ReactNode }) {
  return (
    <Box sx={{ color: 'white', backgroundColor: 'primary.main', p: 2, overflow: 'hidden', ...sx }}>
      <Typography variant='h5'>{children}</Typography>
    </Box>
  );
}

export default FormHeader;
