import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';
import { whitespacesAsNull } from '../../../services/baseApi';

export interface IFormProps<TFormValues extends FieldValues> {
  onSubmit: SubmitHandler<TFormValues>;
  children: React.ReactNode;
}

function Form<TFormValues extends FieldValues>({ onSubmit, children }: IFormProps<TFormValues>) {
  const methods = useFormContext<TFormValues>();
  const submitWrapper: SubmitHandler<TFormValues> = (data, ...rest) => {
    const sanitized = whitespacesAsNull(data);
    onSubmit?.(sanitized, ...rest);
  };
  return (
    <form
      onSubmit={methods.handleSubmit(submitWrapper)}
      style={{ flexGrow: 1, maxWidth: 1200 }}>
      {children}
    </form>
  );
}

export default Form;
