import { Box, Button, Stack, Typography, SxProps } from '@mui/material';
import React from 'react';
import { ActionProperties } from '../2_molecules/ActionItem';

function PageHeader({
  children,
  subHeader,
  action,
  sx,
}: {
  children: React.ReactNode;
  subHeader?: React.ReactNode;
  action?: ActionProperties;
  sx?: SxProps;
}) {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        p: 2,
        // mt: -2,
        // ml: -5,
        // mr: -5,
        // boxShadow: '0px 1px 0px 0px #ddd',
          borderBottom:'solid 1px #ddd',
        ...sx,
      }}>
      <Typography
        variant='h4'
        component='h1'>
        {children}
      </Typography>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'>
        <Box sx={{ flexGrow: 1 }}>
          {subHeader && (
            <Typography
              variant='h6'
              component='h2'>
              {subHeader}
            </Typography>
          )}
        </Box>
        {action && (
          <Button
            size='small'
            variant='outlined'
            disabled={action.disabled}
            onClick={action.onClick}
            startIcon={action.icon}>
            {action.label}
          </Button>
        )}
      </Stack>
    </Box>
  );
}

export default PageHeader;
