import { Box, Paper } from '@mui/material';
import LoginForm from '../../3_organisms/Forms/Account/LoginForm';
import FormHeader from '../../3_organisms/Forms/FormHeader';

function Login() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 4,
        backgroundColor: 'primary.light',
        minHeight: '100vh',
      }}>
      <Paper
        sx={{
          maxWidth: 400,
          minWidth: 400,
          borderRadius: 2,
        }}>
        <Box sx={{ overflow: 'hidden', borderTopLeftRadius: 1, borderTopRightRadius: 1 }}>
          <FormHeader>Ma Carte de Membre</FormHeader>
        </Box>
        <Box sx={{ p: 2, backgroundColor: 'background.paper', borderRadius: 1 }}>
          <LoginForm />
        </Box>
      </Paper>
    </Box>
  );
}

export default Login;
