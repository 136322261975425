import { FormControl, FormLabel, InputAdornment, SxProps, TextField, TextFieldProps } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Theme } from '@mui/material/styles';
import { Lock } from '@mui/icons-material';

export type FormTextInputProps = TextFieldProps & {
  name: string;
  label: string;
  locked?: boolean;
  aide?: string;
};

function FormTextInput({
                         name,
                         label,
                         size = 'small',
                         fullWidth = true,
                         locked = false,
                         disabled,
                         aide,
                         sx,
                         ...rest
                       }: FormTextInputProps) {
  const { register, formState } = useFormContext();
  const error = formState.errors[name];

  const labelId = `${name}_label`;

  const styles: SxProps<Theme> = { ...sx, backgroundColor: locked ? 'grey.200' : '' };

  return (
    <FormControl error={!!error}>
      <FormLabel id={labelId}>{label}</FormLabel>
      <TextField
        {...rest}
        {...register(name)}
        InputProps={locked ?
          {
            endAdornment: (<InputAdornment position="end">
              <Lock sx={{ color: 'grey.400' }} />
            </InputAdornment>),
          } : {
            autoComplete: 'off',

          }
        }
        error={!!error}
        aria-labelledby={labelId}
        size={size}
        fullWidth={fullWidth}
        helperText={error?.message || aide}
        InputLabelProps={{ shrink: true }}
        disabled={disabled || locked}

        sx={styles}
      />

    </FormControl>
  );
}

export default FormTextInput;
