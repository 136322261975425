import React, { createContext } from 'react';
import useModal from '../../../hooks/useModal';
// eslint-disable-next-line import/no-cycle
import Modal from './Modal';

type UseModalResult = ReturnType<typeof useModal>;

const defaultContext: UseModalResult = {
  closeModal: () => {
    /**/
  },
  openModal: () => {
    /**/
  },
  fullscreen: false,
  open: false,
  content: <div />,
};

const ModalContext = createContext<UseModalResult>(defaultContext);
const { Provider } = ModalContext;

function ModalProvider({ children }: { children: React.ReactNode }) {
  const context = useModal();
  return (
    <Provider value={{ ...context }}>
      <Modal />
      {children}
    </Provider>
  );
}

export { ModalContext, ModalProvider };
